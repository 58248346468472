<template>
  <!-- Body -->
  <div class="sidebar__list pos-r overflow-h">
    <BaseLoad v-if="isLoad" class="grid jc-c z4 bg white-transparent" />
    <button
      v-if="isNotBottom"
      type="button"
      class="sidebar__gobottom btn outline rounded cube-30 pos-a z-10"
      @click="gotoBottom()"
    >
      <BaseIcon class="ic-16 black" icon="arrow-down" />
    </button>

    <!-- List chat -->
    <div class="overflow-a flex fd-c h-100" :class="{ 'not-active': isNotActive }" id="scrolled">
      <BaseLoad v-if="isLoadNext" class="grid jc-c rel md pt-20 pb-20" />

      <template v-if="agreementsList && agreementsList.length">
        <!-- Step -->
        <div v-for="item of agreementsList" :key="item.step" class="grid">
          <ItemStep :item="item" :order="order" :currentStep="currentStep" />
        </div>
      </template>

      <template v-if="orderAgreements && orderAgreements.length">
        <ItemWaiting v-for="(item, index) of orderAgreements" :key="item.id" :item="item" :index="index" />
      </template>

      <div
        v-if="!(orderAgreements && orderAgreements.length) && !(agreementsList && agreementsList.length)"
        class="p-20"
      >
        <BaseAlert type="yellow"> Ничего не найдено. </BaseAlert>
      </div>
    </div>
  </div>

  <!-- Foot -->
  <template v-if="isShowForm">
    <Form v-if="order.status.uid === 'in-approval' && isCanSend === 'go'" @updateRows="getRowsBackground" />
    <div v-else class="sidebar__list-foot p-10 ta-center">
      <div v-if="order.status.uid !== 'in-approval'" class="t-red">Согласование еще не началось, зайдите по позже!</div>
      <div v-else-if="isCanSend === 'to-late'" class="t-green">Вы уже проголосовали, остается только наблюдать!</div>
      <div v-else-if="isCanSend === 'wait'" class="t-primary">Ждите своей очереди</div>
      <div v-else-if="isCanSend === 'not-in-list'" class="t-red">Вы не участвуете в согласовании!</div>
    </div>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineProps, toRefs, ref, computed, nextTick, defineExpose } from 'vue'
import { BaseIcon, BaseLoad, BaseAlert } from '@/components'
import ItemStep from './ItemStep.vue'
import ItemWaiting from './ItemWaiting.vue'
import Form from './Form.vue'

// Props
const props = defineProps(['isHasEdit', 'currentFilter', 'isAll', 'order', 'searchText'])

// Data
const store = useStore()
const { isAll, order } = toRefs(props)
const route = useRoute()
const isLoad = ref(false)
const isLoadNext = ref(false)
const isNotBottom = ref(false)

// Computed
const agreementsStore = computed(() => store.getters['agreements/rows'])
const currentStep = computed(() => order.value?.step)
const agreementsList = computed(() => {
  const res = agreementsStore.value

  if (res.findIndex((el) => el.step === currentStep.value) === -1) {
    res.push({
      step: currentStep.value,
      items: []
    })
  }

  return isAll.value ? res : res.filter((el) => el.step === currentStep.value)
})

const profile = computed(() => store.getters.profile)
const flatUsers = computed(() => {
  let list = []

  if (order.value?.agreementers?.length) {
    list = [...order.value?.agreementers]
  } else {
    for (let i = 0; i < order.value?.agreement_departments.length; i++) {
      const element = order.value?.agreement_departments[i]
      const users = element.users
      const manual_users = element.manual_users

      list = list.concat(users, manual_users)
    }
  }

  return list
})
const orderAgreements = computed(() => {
  const currentStepList = agreementsList.value.find((el) => el.step === currentStep.value)
  const usersId = currentStepList?.items?.map((el) => el.user.id) || []
  return flatUsers.value.filter((el) => !usersId.includes(el.id)) || []
})
const isCanSend = computed(() => {
  // 1 вас нет в списке согласующих = not-in-list
  // 2 вы есть но ждите очередь = wait
  // 3 вы должны проголосовать сейчас = go
  // 4 вы уже проголосовали = to-late

  let res = 'not-in-list'
  const notSend = orderAgreements.value.findIndex((el) => el.id === profile.value?.id)

  if (notSend === 0) res = 'go'
  if (notSend > 0) res = 'wait'
  if (notSend === -1) {
    const has = flatUsers.value.findIndex((el) => el.id === profile.value?.id)
    res = has !== -1 ? 'to-late' : 'not-in-list'
  }

  return res
})
const isNotActive = computed(() => {
  switch (order.value.status.uid) {
    case 'in-approval':
    case 'completed':
    case 'canceled':
    case 'draft':
    case 'deleted':
      return false

    default:
      return true
  }
})
const isShowForm = computed(() => {
  switch (order.value.status.uid) {
    case 'completed':
    case 'canceled':
    case 'draft':
    case 'deleted':
      return false

    default:
      return true
  }
})

// Created
getRows()

// Methods
async function getRowsBackground() {
  gotoBottom('auto')
  const payload = {
    orderId: route.params.id,
    page: 1
  }
  await store.dispatch('agreements/GET_ROWS', payload)
  await nextTick()
  gotoBottom('auto')
}
async function getRows() {
  isLoad.value = true
  const payload = {
    orderId: route.params.id,
    page: 1
  }
  await store.dispatch('agreements/GET_ROWS', payload)
  await nextTick()
  gotoBottom('auto')
  isLoad.value = false
}
function gotoBottom(animate = 'smooth') {
  const el = document.getElementById('scrolled')
  if (el) {
    el.scrollTo({
      top: el.scrollHeight,
      behavior: animate
    })
  }
}

// Expose
defineExpose({
  getRows
})
</script>

<style lang="scss" scoped>
.sidebar__list {
  .not-active {
    filter: grayscale(1);
    opacity: 0.7;
  }

  &-foot {
    background-color: var(--box);
  }
}
</style>

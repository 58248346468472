<template>
  <div v-if="item" class="item-chat pl-20 pr-20 pt-10 pb-10">
    <div class="box p-10 grid gtc-2 ggap-10" :class="{ waiting: index > 0 }">
      <!-- User data -->
      <div class="item-chat__user-data flex ai-c ggap-10">
        <a :href="profileLink + item.id" class="item-chat__user-ava cover ava ava-30">
          <img :src="item.avatar" />
        </a>
        <div class="flex ai-c ggap-10">
          <a :href="profileLink + item.id">{{ cutFullName(item.fullname) }}</a>
        </div>
      </div>

      <!-- Event data -->
      <section class="item-chat__section flex ai-c ggap-10 fw-b" :class="{ 't-grey-dark': index > 0 }">
        <div class="fs-18">{{ index === 0 ? '🤔' : '💤' }}</div>
        {{ index === 0 ? 'Сейчас голосует ...' : 'В очереди ...' }}
      </section>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, inject } from 'vue'
import { cutFullName } from '@/plugins'

// Props
const props = defineProps(['item', 'index'])

// Data
const { item, index } = toRefs(props)
const profileLink = inject('profileLink')
</script>

<style lang="scss" scoped>
.item-chat {
  .waiting {
    // filter: grayscale(1);
    // opacity: 0.7;
    background-color: var(--bg);
  }
}
</style>

<template>
  <div v-if="item" class="item-chat grid gtc-auto-1 ggap-10 p-20">
    <!-- Left -->
    <a
      v-if="item.user"
      :href="profileLink + item?.user?.id"
      class="item-chat__user-ava cover ava ava-30"
      v-tippy="item.user.fullname"
    >
      <img :src="item.user.avatar" />
    </a>

    <!-- Right -->
    <div class="grid ggap-5">
      <!-- User data -->
      <div class="item-chat__user-data flex ai-c jc-sb">
        <div class="flex ai-c ggap-10">
          <a :href="profileLink + item?.user?.id">{{ cutFullName(item?.user?.fullname) }}</a>
          <small v-if="item?.user?.id === order.author.id" class="marker white-box">Инициатор</small>
        </div>
        <div class="flex ai-c ggap-10">
          <small class="fs-12 t-grey-dark">{{ formatRuDateTime(item.created_at) }}</small>
          <div v-if="item.id === 'new-message'" class="item-chat__wait">
            <BaseLoad class="rel sm" />
          </div>
        </div>
      </div>

      <!-- Event data -->
      <div class="box overflow-h">
        <section v-if="item.comment" class="item-chat__section">
          <div class="m-0 lh-22 p-10" v-html="DOMPurify.sanitize(item.comment)" />
        </section>
        <section class="item-chat__section flex ai-c ggap-10 p-10 fw-b" :class="{ 't-red': item.status === 3 }">
          <BaseIcon
            v-if="item.status !== 3"
            class="ic-20"
            :class="item.status === 2 ? 'green' : 'red'"
            :icon="currentStatusIcon"
          />
          <div v-else class="fs-18">⛔</div>
          {{ currentStatusText }}
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, toRefs, inject } from 'vue'
import { BaseIcon, BaseLoad } from '@/components'
import { cutFullName, formatRuDateTime } from '@/plugins'
import DOMPurify from 'dompurify'

// Props
const props = defineProps(['item', 'order'])

// Data
const { item, order } = toRefs(props)
const profileLink = inject('profileLink')

// Computed
const currentStatusIcon = computed(() => {
  switch (item.value.status) {
    case 0:
      return 'close-circle'
    case 1:
      return 'arrow-repeat'
    case 2:
      return 'check-circle'
    default:
      return 'Не известно'
  }
})
const currentStatusText = computed(() => {
  switch (item.value.status) {
    case 0:
      return 'Не согласовано'
    case 1:
      return 'Не согласовано - вернуть на рассмотрение'
    case 2:
      return 'Согласовано'
    case 3:
      return 'Перезапуск согласования!'
    default:
      return 'Не известно'
  }
})
</script>

<style lang="scss" scoped>
.item-chat {
  &__user {
    &-ava {
      position: sticky;
      top: 10px;
    }
    &-data {
      height: 30px;
    }
  }

  &__section {
    border-bottom: var(--b1);

    &:last-child {
      border: 0;
    }
  }
}
</style>

<template>
  <div v-if="item" class="step-item" :class="{ old: item.step !== currentStep }">
    <!-- Head -->
    <div class="step-item__head pl-20 pr-20 pt-10 pb-10 flex ai-c jc-sb ggap-10" @click.prevent="toggleAction">
      <div class="flex ai-c ggap-10">
        <button
          v-if="item.step !== currentStep"
          class="department-item__head-btn btn rounded cube-24"
          :class="isOpen ? 'primary' : 'grey'"
        >
          <BaseIcon class="ic-16" :class="isOpen ? 'white' : 'black'" :icon="isOpen ? 'arrow-down' : 'arrow-right'" />
        </button>
        <template v-if="item.step !== currentStep">
          <h4 class="title t-grey-dark">Этап №{{ item.step }}</h4>
          <small class="marker grey">Старый этап</small>
        </template>
        <template v-else>
          <div
            class="step-item__finished flex ai-c jc-c"
            :class="{ not: itemListLength !== orderAgreementsLength }"
            v-tippy="
              itemListLength === orderAgreementsLength ? 'Согласование завершено' : 'Согалсование все еще в процессе'
            "
          >
            <!-- <BaseLoad v-if="itemListLength !== orderAgreementsLength" class="rel sm" /> -->
            <BaseIcon v-if="itemListLength !== orderAgreementsLength" class="ic-18 grey" icon="check" />
            <BaseIcon v-else class="ic-18 white" icon="check" />
          </div>
          <h2 class="title">Этап №{{ item.step }}</h2>
          <small class="marker green">Текущий этап</small>
        </template>
      </div>

      <div class="flex ai-c ggap-10">
        <template v-if="orderAgreementsLength">
          Согласовали:
          <div v-if="itemListLength !== orderAgreementsLength" class="flex ai-c ggap-5">
            <b :class="markerColor">
              {{ itemListLength }}
            </b>
            из {{ orderAgreementsLength }}
          </div>
          <small v-else class="marker green"> все </small>
        </template>
        <small v-else class="marker red">Нет согласующих</small>
      </div>
    </div>

    <!-- Body -->
    <div v-if="isOpen" class="step-item__body grid">
      <Item v-for="el of item.items" :key="el.id" :item="el" :order="order" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, toRefs, computed } from 'vue'
import { BaseIcon } from '@/components'
import Item from './Item.vue'

// Props
const props = defineProps(['item', 'order', 'currentStep'])

// Data
const { item, order, currentStep } = toRefs(props)
const isOpen = ref(false)

// Computed
const orderAgreementsLength = computed(() => {
  let departmentLength = 0
  const departments = order.value?.agreement_departments

  for (let i = 0; i < departments.length; i++) {
    const element = departments[i]
    departmentLength = departmentLength + element?.manual_users?.length + element.users?.length
  }

  return departmentLength || order.value?.agreementers?.length
})
const itemListLength = computed(() => item.value?.items.filter((el) => el.status !== 3).length || 0)
const markerColor = computed(() => {
  let res = 't-red'
  if (itemListLength.value === orderAgreementsLength.value) res = 't-green'
  else if (itemListLength.value < orderAgreementsLength.value) res = 't-red'
  return res
})

// Created
isOpen.value = currentStep.value === item.value.step

// Methods
function toggleAction() {
  if (item.value.step !== currentStep.value) isOpen.value = !isOpen.value
  else isOpen.value = true
}
</script>

<style lang="scss" scoped>
.step-item {
  &__head {
    background-color: var(--box);
    border-bottom: var(--b1);
    height: 50px;
  }

  &.old > &__head:hover {
    background-color: var(--grey-l);
  }

  &__finished {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background-color: var(--green);

    &.not {
      background-color: var(--grey);
    }
  }
}
</style>

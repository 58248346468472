<template>
  <form class="comment-form p-10 grid ggap-5 pos-r" @submit.prevent="submitAction">
    <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white-transparent" />
    <FormTextarea label="" placeholder="Напишите комментарий ..." rows="1" v-model="formData.message" class="f-1" />

    <!-- Actions -->
    <div class="flex ai-fs jc-sb ggap-10">
      <BaseDropDown btn-class="btn grey sm pl-10 pr-10 ggap-20" btn-active-class="active" ref="resultDropDown">
        <template #btn>
          <div class="flex ai-c ggap-10">
            <BaseIcon class="ic-16" :class="formData.type === 'agree' ? 'green' : 'red'" :icon="currentItem.icon" />
            {{ currentItem.text }}
          </div>
          <BaseIcon class="ic-16 black" icon="arrow-down" />
        </template>

        <template #body>
          <div class="box p-5 grid ggap-5" @click.stop>
            <button
              v-for="type of optionsType"
              :key="type.value"
              class="btn transparent-grey w-100 sm"
              :class="{ active: formData.type === type.value }"
              @click.stop="formData.type = type.value"
            >
              <BaseIcon class="ic-16 primary" :icon="type.icon" />
              {{ type.text }}
            </button>
          </div>
        </template>
      </BaseDropDown>

      <!-- Select -->
      <FormSelect
        v-if="false"
        class="sm"
        label=""
        placeholder=""
        :options="optionsType"
        required
        v-model="formData.type"
      />

      <!-- Submit -->
      <button type="submit" class="btn sm primary" :disabled="!isActive">Отправить</button>
    </div>
  </form>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, defineEmits, nextTick, reactive, ref, watch } from 'vue'
import { BaseIcon, BaseLoad, BaseDropDown, FormTextarea, FormSelect } from '@/components'
import agreements from '@/api/modules/agreements'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['updateRows'])

// Data
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const resultDropDown = ref()
const optionsType = ref([
  {
    icon: 'check-circle',
    text: 'Согласовано',
    value: 'agree'
  },
  {
    icon: 'close-circle',
    text: 'Не согласовано',
    value: 'not-agree'
  },
  {
    icon: 'arrow-repeat',
    text: 'Не согласовано и вернуть на рассмотрение',
    value: 'not-agree-back'
  }
])
const formData = reactive({
  type: 'agree',
  message: ''
})

// Watch
watch(
  () => formData.type,
  () => resultDropDown.value?.closeAction()
)

// Computed
const currentItem = computed(() => optionsType.value.find((el) => el.value === formData.type))
const isActive = computed(() => formData.type === 'agree' || !!formData.message)
const currentStatus = computed(() => {
  switch (formData.type) {
    case 'agree':
      return 2
    case 'not-agree':
      return 0
    case 'not-agree-back':
      return 1
    default:
      return 0
  }
})
const user = computed(() => store.getters.profile)

// Methods
function submitAction() {
  isLoad.value = true
  const cleanedMessage = formData.message.split('<p>&nbsp;</p>').join('')
  agreements
    .comment(route.params.id, { comment: DOMPurify.sanitize(cleanedMessage), status: currentStatus.value })
    .then(async () => {
      const payload = {
        id: 'new-message',
        comment: formData.message,
        status: currentStatus.value,
        user: {
          ...user.value
        }
      }
      store.commit('agreements/PUSH_ITEM', payload)
      await nextTick()
      emits('updateRows')
      formData.message = ' '
      await nextTick()
      formData.message = ''
    })
    .finally(() => (isLoad.value = false))
}
</script>

<style lang="scss" scoped>
.comment-form {
  background-color: var(--box);
  border-top: var(--b1);

  &__files {
    &-input {
      display: none;
    }

    &-list {
      max-height: 220px;
    }
  }
}
</style>
